import { createContext, useState, useRef } from 'react';
import cogoToast from "cogo-toast";

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const prod = process.env.REACT_APP_STAGE === "prod" ? true : false;
    const [websocketResponse, setWebsocketResponse] = useState();
    const [websocketData, setWebsocketData] = useState();
    const [websocketStatus, setWebsocketStatus] = useState({
        status: false,
        data: null,
    });
    const [isConnected, setIsConnected] = useState(false);
    const socketRef = useRef(null);

    const connectWebSocket = (onOpenCallback) => {
        if (!isConnected) {
            // socketRef.current = new WebSocket(prod ? 'wss://t0rzjb9iel.execute-api.ap-south-1.amazonaws.com/production/' 
            // : 'wss://n9m42x6yk7.execute-api.ap-south-1.amazonaws.com/test');
            socketRef.current = new WebSocket('wss://t0rzjb9iel.execute-api.ap-south-1.amazonaws.com/production/');

            socketRef.current.onopen = () => {
                console.log('WebSocket connected');
                setIsConnected(true);
                  // Trigger callback after successful connection
      if (onOpenCallback) {
        onOpenCallback(); // Resend payload or perform other actions
      }
            };

            socketRef.current.onclose = (event) => {
                console.log('WebSocket disconnected', event);
                setIsConnected(false);
                if (event.wasClean) {
                    console.log(
                        `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                    );
                } else {
                    console.log(`[close] Connection died; code=${event.code}`);
                }
            };

            socketRef.current.onerror = function (error) {
                setIsConnected(false);
                console.log(`[error] ${JSON.stringify(error)}`);
            };

        }
    };

    const disconnectWebSocket = () => {
        if (socketRef.current) {
            socketRef.current.close();
            socketRef.current = null;
            setIsConnected(false);
            console.log('WebSocket manually disconnected');
        }
    };

    const triggerSocketAction = (payload) => {
        console.log(payload)
        if (socketRef.current && isConnected) {
            triggerSocket(payload)
        } else {
            console.log('WebSocket is not connected. Reconnecting...');
            connectWebSocket(() => {
                // Resend payload after successful connection
                console.log('Retrying payload after connection...');
                triggerSocket(payload)
            });
        }
    };

    const triggerSocket = (payload) => {
        try {
            console.log('Socket triggering', payload)
            socketRef.current.send(JSON.stringify(payload));
            socketRef.current.onmessage = function ({ data }) {
                let res = JSON.parse(data);

                console.log(res)

                if (res) {
                    setWebsocketResponse(res);
                }

                if (res?.progress) {
                    websocketStatus.status = true;
                    websocketStatus.data = res?.progress;
                    setWebsocketStatus({ ...websocketStatus });
                }

                if (res?.success_application_count || res?.success_interview_count || res?.success_prescreen_count || res?.failed_reason) {
                    websocketStatus.status = false;
                    websocketStatus.data = "";
                    setWebsocketStatus({ ...websocketStatus });
                    setWebsocketData(res);
                }

                if (res?.message === 'Internal server error') {
                    disconnectWebSocket();
                }

            }

        } catch (error) {
            cogoToast.error(
                error?.data.msg ||
                error?.data.data?.toString() ||
                error?.data?.toString() ||
                error?.toString() ||
                "File could not be uploaded",
                {
                    position: "top-right",
                }
            );
        }
    }

    const resetWebSocket = () => {
        disconnectWebSocket()
        setWebsocketData(null);
        setWebsocketStatus({
            status: false,
            data: null,
        });
    }

    return (
        <WebSocketContext.Provider
            value={{
                websocketData,
                websocketStatus,
                websocketResponse,
                isConnected,
                connectWebSocket,
                resetWebSocket,
                triggerSocketAction,
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
};
